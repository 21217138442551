import {createContext} from '@lit/context';
import type {
  Privileges,
  PrivilegeState,
  Quotas,
  UserPreferences,
} from '../../api-client/users/types';
import {
  LS_RULES_KEY,
  LS_RULESET_NAME_KEY,
  LS_RULESET_NOTIFICATION_EMAILS_KEY,
  SESSION_MAX_AGE,
} from '../defaults';

export interface CurrentUserData {
  id: string;
  first_name: string;
  last_name: string;
  privileges: Record<string, PrivilegeState>;
  preferences: UserPreferences | undefined;
  quotas: Quotas | undefined;
  main_group_id?: string;
  audiences: string[] | undefined;
  ga_id: string | undefined; // Google Analytics ID
}

export class CurrentUser {
  public readonly id: string;
  public readonly firstName: string;
  public readonly lastName: string;
  public readonly privileges: Readonly<Privileges>;
  public readonly preferences?: Readonly<UserPreferences> | undefined;
  public readonly quotas?: Readonly<Quotas> | undefined;
  public readonly mainGroupId?: string;
  public readonly audiences?: string[] | undefined;
  public readonly ga_id?: string | undefined;

  constructor(data: CurrentUserData) {
    this.id = data.id;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.privileges = data.privileges;
    this.preferences = data.preferences;
    this.quotas = data.quotas;
    this.mainGroupId = data.main_group_id;
    this.audiences = data.audiences;
    this.ga_id = data.ga_id;
  }

  public get hasGTIAccess() {
    return this.privileges['google-threat-intel']?.granted;
  }

  public toJSON(): CurrentUserData {
    return {
      id: this.id,
      first_name: this.firstName,
      last_name: this.lastName,
      preferences: this.preferences,
      privileges: this.privileges,
      quotas: this.quotas,
      main_group_id: this.mainGroupId,
      audiences: this.audiences,
      ga_id: this.ga_id,
    };
  }
}

export type ValidationSubscription = (changed: boolean) => void;
export type Unsubscribe = () => void;

export interface UserSession {
  readonly currentUser: CurrentUser | undefined;
  /**
   * Adds a new subscription to session validation
   */
  readonly onValidated: (cb: ValidationSubscription) => Unsubscribe;
  readonly validate: () => Promise<void>;
  readonly signOut: () => Promise<void>;
}

const IGNORED_PROPERTIES_DIFF: (keyof CurrentUserData)[] = [
  'preferences',
  'quotas',
];

const LS_ITEMS_CRITICAL_KEYS = [
  'vt-referrer',
  'colorMode',
  LS_RULES_KEY,
  LS_RULESET_NAME_KEY,
  LS_RULESET_NOTIFICATION_EMAILS_KEY,
];

/*
 * We intentionally ignore preferences and quotas to check equality because they change with too much frequency and
 * triggers the page reloading when it isn't necessary.
 */
const checkDiff = (
  localUser?: CurrentUser,
  remoteUser?: CurrentUser,
  ignoredProps = IGNORED_PROPERTIES_DIFF
) => {
  const overriddenProperties = ignoredProps.reduce(
    (a, b) => ({
      ...a,
      [b]: null,
    }),
    {} as Record<keyof CurrentUserData, null>
  );

  return (
    JSON.stringify(
      localUser
        ? {
            ...localUser.toJSON(),
            ...overriddenProperties,
          }
        : undefined
    ) !==
    JSON.stringify(
      remoteUser
        ? {
            ...remoteUser.toJSON(),
            ...overriddenProperties,
          }
        : undefined
    )
  );
};

function safeParse(cachedUserJSON: string) {
  try {
    return JSON.parse(cachedUserJSON) as CurrentUserData;
  } catch (e) {
    const err = e instanceof Error ? e : new Error('Parsing error');
    console.error(err);
    return null;
  }
}

const USER_SERVICE_LS_KEY = 'vt-ui-main-current-user';

export class LSUserManager implements UserSession {
  private localUser?: CurrentUser;
  private validationSubscriptions = new Set<ValidationSubscription>();
  public get currentUser() {
    return this.localUser;
  }

  constructor(
    private readonly remoteUser: () => Promise<CurrentUserData>,
    private readonly signOutCall: () => Promise<void>
  ) {
    const cachedUser = localStorage.getItem(USER_SERVICE_LS_KEY) || '""';
    const maybeParsed = safeParse(cachedUser);
    this.localUser = maybeParsed ? new CurrentUser(maybeParsed) : undefined;
  }

  public async validate() {
    const changed = await this.remoteUser()
      .then((data) => new CurrentUser(data))
      .then((remoteUser) => {
        if (checkDiff(this.currentUser, remoteUser)) {
          this.sessionChanged(remoteUser);
          return true;
        } else {
          // We want to update the LS user to keep the preferences up to date, but avoid reloading the page.
          this.updateLS(remoteUser);
          return false;
        }
      })
      .catch(() => {
        if (checkDiff(this.currentUser, undefined)) {
          this.sessionChanged(undefined);
          return true;
        }
        return false;
      });
    this.notifySubscribers(changed);
  }

  private notifySubscribers(changed: boolean) {
    for (const sub of this.validationSubscriptions) {
      sub(changed);
    }
  }

  public onValidated(cb: ValidationSubscription) {
    this.validationSubscriptions.add(cb);
    return () => this.validationSubscriptions.delete(cb);
  }

  public async signOut() {
    await this.signOutCall();
    this.sessionChanged(undefined);
    this.notifySubscribers(true);
  }

  private sessionChanged(newFormattedUser: CurrentUser | undefined): void {
    this.updateLS(newFormattedUser);
  }

  private updateLS(newFormattedUser?: CurrentUser) {
    this.clearStorage(LS_ITEMS_CRITICAL_KEYS);
    this.localUser = newFormattedUser;
    localStorage.removeItem(USER_SERVICE_LS_KEY);
    if (this.currentUser) {
      try {
        localStorage.setItem(
          USER_SERVICE_LS_KEY,
          JSON.stringify(newFormattedUser)
        );
      } catch (e) {
        const err =
          e instanceof Error ? e : new Error('Parsing or storage error');

        if (
          err.name !== 'QUOTA_EXCEEDED_ERR' &&
          err.name !== 'NS_ERROR_DOM_QUOTA_REACHED' &&
          err.name !== 'QuotaExceededError'
        ) {
          throw e;
        }
        // try to make room before setting the user object.
        localStorage.removeItem('vtg-entity-list-search');
        localStorage.setItem(
          USER_SERVICE_LS_KEY,
          JSON.stringify(newFormattedUser)
        );
      }
    }
  }
  /**
   * Clears localStorage while keeping key values
   */
  private clearStorage(keysToSave?: string[]) {
    const savedValues = keysToSave?.map((key) => ({
      key,
      value: localStorage.getItem(key),
    }));
    localStorage.clear();
    savedValues?.forEach(
      (savedValue) =>
        savedValue.value &&
        localStorage.setItem(savedValue.key, savedValue.value)
    );
  }
}

export const currentUserContext = createContext<CurrentUser>('currentUser');

export const sessionContext = createContext<UserSession>('userSession');

/**
 * A cross-subdomain inactivity manager using only:
 *   - A shared "lastActivityTime" cookie (Domain=.virustotal.com).
 *   - A shared "logout" cookie to indicate forced logout.
 *   - Local event listeners to detect user activity in THIS tab.
 *   - A single polling interval to:
 *       1) Detect remote logout requests (via "logout" cookie).
 *       2) Detect remote activity (newer timestamp in "lastActivityTime").
 *       3) Check if THIS tab's inactivity threshold is exceeded.
 *
 * No setTimeout is used; inactivity detection is done via polling every X ms.
 *
 * Usage example:
 *
 *   const manager = new InactivityManager({
 *     inactivityThresholdMs: 900_000,  // 15 minutes in ms
 *     checkIntervalMs: 5000,           // poll every 5s
 *     cookieDomain: '.virustotal.com',
 *     onLogout: () => {
 *       // e.g. redirect to /logout or clear tokens
 *       window.location.href = '/logout';
 *     },
 *   });
 *
 *   manager.start();
 */

import {throttle} from 'lodash';

interface InactivityManagerOptions {
  /**
   * Inactivity threshold in milliseconds.
   * If no activity for this duration, we consider the user inactive.
   */
  inactivityThresholdMs?: number;

  /**
   * Function to call when logging out.
   */
  onInactivity?: () => void;

  /**
   * Which user events on `window` count as activity?
   */
  activityEvents?: string[];

  /**
   * How often (in ms) to poll the cookie for cross-tab/subdomain updates,
   * and check local inactivity.
   */
  checkIntervalMs?: number;

  /**
   * The domain for the shared cookie (e.g., '.virustotal.com').
   */
  cookieDomain?: string;

  /**
   * Name of the cookie storing the "last activity" timestamp (ms).
   */
  activityCookieName?: string;

  /**
   * Name of the cookie storing the "logout" flag.
   */
  logoutCookieName?: string;
}

export class InactivityManager {
  private inactivityThresholdMs: number;
  private onInactivity: () => void;
  private activityEvents: string[];
  private checkIntervalMs: number;
  private cookieDomain: string;
  private activityCookieName: string;
  private logoutCookieName: string;

  // Polling interval ID
  private cookieCheckIntervalId: number | null = null;

  // Track the last time of user activity in THIS tab (ms).
  private localLastActivityMs: number = Date.now();

  constructor({
    inactivityThresholdMs = SESSION_MAX_AGE,
    onInactivity = () => {
      console.warn('[InactivityManager] No onInactivity handler provided.');
    },
    activityEvents = ['click', 'keydown', 'mousemove', 'touchstart', 'scroll'],
    checkIntervalMs = 5000,
    cookieDomain = '.virustotal.com',
    activityCookieName = 'lastActivityTime',
    logoutCookieName = 'logoutFlag',
  }: InactivityManagerOptions = {}) {
    this.inactivityThresholdMs = inactivityThresholdMs;
    this.onInactivity = onInactivity;
    this.activityEvents = activityEvents;
    this.checkIntervalMs = checkIntervalMs;
    this.cookieDomain = cookieDomain;
    this.activityCookieName = activityCookieName;
    this.logoutCookieName = logoutCookieName;

    // Bind methods
    this.handleLocalActivity = throttle(
      this.handleLocalActivity.bind(this),
      4000,
      {leading: true, trailing: true}
    );
    this.pollCookie = this.pollCookie.bind(this);
  }

  /**
   * Start listening for user activity and start polling.
   */
  public start(): void {
    // 1. Listen for user activity in this tab
    this.activityEvents.forEach((evt) => {
      window.addEventListener(evt, this.handleLocalActivity, {passive: true});
    });

    // 2. Initialize the shared cookie to "now" so other tabs see we are active
    this.updateActivityCookie(Date.now());
    this.clearLogoutCookie();

    // 3. Start the polling interval
    this.cookieCheckIntervalId = window.setInterval(
      this.pollCookie,
      this.checkIntervalMs
    );
  }

  /**
   * Stop polling and remove event listeners.
   */
  public stop(): void {
    // Stop listening for user events
    this.activityEvents.forEach((evt) => {
      window.removeEventListener(evt, this.handleLocalActivity);
    });

    // Stop polling
    if (this.cookieCheckIntervalId !== null) {
      clearInterval(this.cookieCheckIntervalId);
      this.cookieCheckIntervalId = null;
    }
  }

  /**
   * Called whenever local user activity occurs (click, keydown, etc.).
   */
  private handleLocalActivity(): void {
    const now = Date.now();
    // Update local record
    this.localLastActivityMs = now;

    // Update the shared cookie
    this.updateActivityCookie(now);

    // Clear any logout cookie set by another tab
    this.clearLogoutCookie();
  }

  /**
   * The function we call periodically to:
   * 1) Check if "logoutFlag" cookie is set => immediate logout.
   * 2) Check if there's a more recent "lastActivityTime" => sync local.
   * 3) Check if local inactivity is past threshold => set logout cookie + logout.
   */
  private pollCookie(): void {
    // 1) Check logout cookie
    if (this.readLogoutCookie()) {
      // Another tab triggered logout
      this.handleLogout();
      return;
    }

    // 2) Check "lastActivityTime" from the cookie
    const cookieLastActivity = this.readActivityCookie();
    if (cookieLastActivity && cookieLastActivity > this.localLastActivityMs) {
      // Remote activity is newer
      this.localLastActivityMs = cookieLastActivity;
    }

    // 3) Check local inactivity
    const now = Date.now();
    if (now - this.localLastActivityMs > this.inactivityThresholdMs) {
      // Past threshold => set logout cookie => log out
      this.setLogoutCookie();
      this.handleLogout();
    }
  }

  /**
   * Handle logout steps in THIS tab
   */
  private handleLogout(): void {
    this.stop();
    this.onInactivity();
  }

  /**
   * Update the last activity cookie
   */
  private updateActivityCookie(timestamp: number): void {
    const expires = new Date(
      Date.now() + 7 * 24 * 60 * 60 * 1000
    ).toUTCString(); // e.g. 7-day expiry
    document.cookie = `${this.activityCookieName}=${timestamp}; Expires=${expires}; Path=/; Domain=${this.cookieDomain}`;
  }

  /**
   * Read the lastActivityTime from the shared cookie
   */
  private readActivityCookie(): number | null {
    const match = document.cookie.match(
      new RegExp(`(^| )${this.activityCookieName}=([^;]+)`)
    );
    if (match && match[2]) {
      const val = parseInt(match[2], 10);
      if (!isNaN(val)) {
        return val;
      }
    }
    return null;
  }

  /**
   * Set a "logout" flag in the cookie (so other tabs see it and log out).
   */
  private setLogoutCookie(): void {
    const expires = new Date(Date.now() + 60 * 60 * 1000).toUTCString(); // e.g. 1-hour expiry
    document.cookie = `${this.logoutCookieName}=1; Expires=${expires}; Path=/; Domain=${this.cookieDomain}`;
  }

  /**
   * Check if the "logout" cookie is set
   */
  private readLogoutCookie(): boolean {
    const match = document.cookie.match(
      new RegExp(`(^| )${this.logoutCookieName}=([^;]+)`)
    );
    return !!(match && match[2]);
  }

  /**
   * Clear the logout cookie
   */
  private clearLogoutCookie(): void {
    document.cookie = `${this.logoutCookieName}=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; Domain=${this.cookieDomain}`;
  }
}
